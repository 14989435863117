export default [
 
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: {
            title: "Register",
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
        },
    },
    {
        path: "/qr-details",
        name: "profile",
        meta: { title: "Profile", authRequired: true },
        component: () => import("../views/pages/profile/simple"),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            title: "Reset Password", // authRequired: true,
        },
        component: () => import('../views/auth/create/reset')
    },
    {
        path: '/twostep',
        name: 'twostep-basic',
        meta: {
            title: "Two Step Auth", //authRequired: true,
        },
        component: () => import('../views/auth/twostep/basic')
    },
    {
        path: "/qr-list",
        name: "projects-list",
        meta: { title: "Projects List", authRequired: true },
        component: () => import("../views/apps/projects/list"),
    },
    {
        path: "/qr-create",
        name: "projects-create",
        meta: { title: "Projects Create", authRequired: true },
        component: () => import("../views/apps/projects/create"),
    },
];